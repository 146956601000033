import React from 'react';
import NavB from './Navbar';
import Home from './Home';
import Pricing from './Pricing';
import Footer from './Footer';
import Contact from './Contact';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Portfolio from './Portfolio';

function Main() {
  return (
    <>
      <Router>
        <NavB />
        
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route path ='/pricing' component={Pricing}/>
          <Route path='/portfolio' component={Portfolio} />
          <Route path='/contact' component={Contact}/>
        </Switch>

        <Footer />
      </Router>
    </>
    
  )
}

export default Main
