import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import {Link} from 'react-router-dom';

class NavB extends Component {
  render() {
    return (
      <div className='pb-5'>
        <Navbar collapseOnSelect expand='lg' bg="dark" variant="dark" fixed="top">
          <Navbar.Brand href="#home"><img src='./img/IbSitesLogoWhiteOutline.png' height='80px' alt='ibsites logo'/></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">

            <Nav className="mr-auto">
              <Nav.Link><Link to='/' className='navLinks'>Home</Link></Nav.Link>
              <Nav.Link><Link to='/pricing' className='navLinks'>Pricing</Link></Nav.Link>
              <Nav.Link><Link to='/portfolio' className='navLinks'>View Portfolio</Link></Nav.Link>
              <Nav.Link><Link to='/contact' className='navLinks'>Contact Us</Link></Nav.Link>
              
            </Nav>

          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}
export default NavB;