import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { SiInstagram } from 'react-icons/si';
// import { FaFacebook } from 'react-icons/fa';
import {Link} from 'react-router-dom';


function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className='bg-dark text-light'>
      <Container className='p-5 fs-1'>
        <Row>
          <Col>
            <h5 className='fs-4'>Follow</h5>
            <a
              href='https://www.instagram.com/ibsites/' className='text-light'>
              <SiInstagram />
            </a>
            {/* <a
              href='https://www.facebook.com/ideabprinting/'
              className='text-light pl-3'>
              <FaFacebook />
            </a> */}
          </Col>

          <Col>
            <h5 className='fs-4'>Links</h5>
            <ul className='list-unstyled fs-5'>
              <li><Link to='/' className='footerLinks'>Home</Link></li>
              <li><Link to='/pricing' className='footerLinks'>Pricing</Link></li>
              <li><Link to='/portfolio' className='footerLinks'>View Portfolio</Link></li>
              <li><Link to='/contact' className='footerLinks'>Contact Us</Link></li>
            </ul>
            
          </Col>

          <Col>
            <a href="#home"><img src='./img/IbSitesLogoWhiteOutline.png' height='90px' alt='ibsites logo'/></a>
            
            <ul className='list-unstyled fs-5'>
              <li>3277 Lake Worth Rd # B, Palm Springs, FL 33461</li>
              <li><a href='tel:+5617211473' className='unstyled'>(561) 721-1473</a></li>
              <li><a href='mailto:francisco@ibsites.com' className='unstyled'>francisco@ibsites.com</a></li>
              <li>Monday - Friday</li>
              <li>9AM - 6PM</li>
            </ul>
            
          </Col>
        </Row>
        <div className='fs-6'>Copyright &#169; {year}, IBSITES.com, All Rights Reserved</div>
        
      </Container>
      
    </div>
  )
}

export default Footer
