import React from 'react';
import { MdContacts } from 'react-icons/md';
import { BiMap, BiPhone } from 'react-icons/bi';
import { GrMail } from 'react-icons/gr';
import { SiInstagram } from 'react-icons/si';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';

function Contact() {
  return (
    <div className='bg-dark'>
      <Jumbotron className=' d-flex justify-content-center align-items-center flex-column cBackground'>

        <MdContacts fontSize='3rem' className='mt-5'/>
        <h1>Contact Us</h1>
        
      </Jumbotron>

      <Container className='fs-5'>
        <Row className='contact'>
          <Col className='p-5 text-center'>
            <h3 className='text-center d-flex flex-column align-items-center'>Info</h3>

            <div className='p-3'>
              <h4> <BiMap fontSize='1.5rem' /> 3277 Lake Worth Rd # B, Palm Springs, FL 33461</h4>
              <br/>
              <h4> <BiPhone fontSize='1.5rem' /><a href='tel:+5617211473' className='unstyled2'> (561) 721-1473</a></h4>
              <br/>
              <h4> <GrMail fontSize='1.5rem' /><a href='mailto:francisco@ibsites.com' className='unstyled2'> francisco@ibsites.com</a></h4>
              <br />

              <Container className='d-flex justify-content-center'>
              <a
              href='https://www.instagram.com/ibsites/' className='unstyled2 '>
                <SiInstagram fontSize='50px' />
              </a>
              

              </Container>

            </div>
            
          </Col>

          
        </Row>
      </Container>
    </div>
  )
}

export default Contact
