import React from 'react';
import { IoPricetagsSharp } from 'react-icons/io5';
import { Jumbotron, Row, Col, Container } from 'react-bootstrap';
import { BiPhone } from 'react-icons/bi';
import { GrMail } from 'react-icons/gr';
import { SiInstagram } from 'react-icons/si';

function Pricing() {
  return (
    <>
      <Jumbotron className='p-6 d-flex justify-content-center align-items-center flex-column pBackground'>

        <IoPricetagsSharp fontSize='3rem' className='mt-5'/>
        <h1>Pricing</h1>
        
      </Jumbotron>

      <Container>
        {/* BASE PRICES */}
        <h2>Base Prices</h2>
        <Row>
          <OnePage />
          <MultiPage />
        </Row>

        {/* CHARGES ON BASE PRICE */}
        <h2 className='pt-5'>Charges on Base Price</h2>
        <AddOns />

        {/* CONTACT */}
        <div className='Contact text-light mt-5 mb-5 '>
          <div className='text-center'>
            <h2 className='pt-5'>Contact </h2>
            <p className='fs-4'>Please contact us in order to receive a free estimate and the project plan that can be started with a little as a 20% deposit.</p>
          </div>

          <div className='p-3 text-center'>
              
            <h4> <BiPhone fontSize='1.5rem' /><a href='tel:+5617211473' className='unstyled2 text-light'> (561) 721-1473</a></h4>
            <br/>
            <h4> <GrMail fontSize='1.5rem' /><a href='mailto:francisco@ibsites.com' className='unstyled2 text-light'> francisco@ibsites.com</a></h4>
            <br />

            <Container className='d-flex justify-content-center'>
            <a
            href='https://www.instagram.com/ibsites/' className='unstyled2 text-light'>
              <SiInstagram fontSize='50px' />
            </a>
            

            </Container>

          </div>
            
        </div>

      </Container>
    </>
  )
}

function OnePage() {
  return (
    <Col className='PricingTab p-5 mt-5 m-1' id='OnePage'>

          <Col md={12}  >
            <h2 className='pb-2 Bold'>One Page Website</h2>
            <p className='fs-5'>Standard price for a single page website.</p>
          </Col>

          <Col className='boxes' md={12}>
            <Row className='d-flex justify-content-center align-items-center'>
              <Col md={5}>
                <h5 className='text-center' ><span id='dollar'>$450</span></h5>
              </Col>

              <Col>
                <h5 className='text-center'>+</h5>
              </Col>

              <Col md={5}>
                <h5 className='text-center'>Domain Payment Per Year</h5>
              </Col>
            </Row>
          </Col>
          
          
    </Col>
  )
}

function MultiPage() {
  return (
    <Col className='PricingTab p-5 mt-5 m-1' id='MultiPage'>

          <Col md={12}  >
            <h2 className='pb-2 Bold'>Multi-Page Website</h2>
            <p className='fs-5'>Standard price range for a multi-page website.</p>
          </Col>

          <Col className='boxes' md={12}>
            <Row className='d-flex justify-content-center align-items-center'>
              <Col md={5}>
                <h5 className='text-center' ><span id='dollar'>$600</span></h5>
              </Col>

              <Col>
                <h5 className='text-center'>-</h5>
              </Col>

              <Col md={5}>
                <h5 className='text-center'><span id='dollar'>$800</span></h5>
              </Col>
            </Row>
          </Col>
          
        <Col md={12}  >
            <p className='fs-5 text-center'>*Domain Payment Per Year*</p>
        </Col>
          
    </Col>
  )
}
function AddOns() {
  return (
    <Row>
          <Col  className='PricingTab d-flex flex-column align-items-center m-1 text-light'>
            <img src='./img/Google.png' width='300px' height='300px' alt='google my business' className='p-2'/>
            <h2>Google My Business</h2>
            <div className='boxes'>
              <h3><span id='dollar2' className='text-dark'>$10</span></h3>
            </div>
            <p className='fs-5 text-center'>Service fee for creating your Google Business listing.</p>
          </Col>

          <Col  className='PricingTab d-flex flex-column align-items-center m-1 text-light'>
            <img src='./img/MenuQR.png' width='300px' height='300px' alt='google my business' className='p-2'/>
            <h2>Contactless Menu with QR Code</h2>
            <div className='boxes'>
              <h3><span id='dollar2' className='text-dark'>$200</span></h3>
            </div>
            <p className='fs-5 text-center'>Service fee for creating a contact less menu onto your website. Includes table stands for QR Codes.</p>
          </Col>

          <Col  className='PricingTab d-flex flex-column align-items-center m-1 text-light'>
            <img src='./img/Menu.png' width='300px' height='300px' alt='google my business' className='p-2'/>
            <h2>Contactless Menu</h2>
            <div className='boxes'>
              <h3><span id='dollar2' className='text-dark'>$150</span></h3>
            </div>
            <p className='fs-5 text-center'>Service fee for creating a contact less menu onto your website. Does not include QR codes.</p>
          </Col>
          <Col  className='PricingTab d-flex flex-column align-items-center m-1 text-light'>
            <img src='./img/ECommerce.png' width='300px' height='300px' alt='google my business' className='p-2'/>
            <h2>E-Commerce</h2>
            <div className='boxes'>
              <h3><span id='dollar2' className='text-dark'>$400</span></h3>
            </div>
            <p className='fs-5 text-center'>Service fee adding E-Commerce onto your website. Only for multi-page websites.</p>
          </Col>
          
        </Row>
  )
}



export default Pricing
