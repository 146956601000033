import React from 'react';
import {Jumbotron, Button, Col, Row,Container} from 'react-bootstrap'

function Home() {
  return (
    <div>
      <Jumbotron className='imgBackground d-flex flex-column justify-content-center align-content-center'>
        
        <div className='text-light text-center pt-5 fs-4 '>
          <h1>Websites from Idea Builder</h1>
          <p>
            Responsive, professional, and stylish.
          </p>
          <p>
            <Button variant="primary" href='#learn'>Learn more</Button>
          </p>
        </div>
      </Jumbotron>

      <Container id='learn'>
        <Row >
          <Col md={6} className='d-flex flex-column justify-content-center p-5'>
            <h3>Located Here in Palm Springs, Florida</h3>
            <p>We can provide you a well made website for your business or advertising need.</p>
          </Col>
          <Col md={6} className='p-1'>
            <img src='./img/storeFront.png' width='100%' alt='storeFront'/>
          </Col>

          <Col md={6} className='p-1'>
            <img src='./img/code1.jpg' width='100%' alt='codePicture'/>
          </Col>
          <Col md={6} className='d-flex flex-column justify-content-center p-5'>
            <h3>No WordPress</h3>
            <p>We build our websites with pure code. We don't use Wordpress or any other website builders.</p>
          </Col>

          <Col md={6} className='d-flex flex-column justify-content-center p-5'>
            <h3>Well Built</h3>
            <p>Our websites are responsive and made to fit on all screens.</p>
          </Col>
          <Col md={6} className='p-1'>
            <img src='./img/devices1.jpg' width='100%' alt='range of devices'/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home;
