import React from 'react';
import { FaSuitcase } from 'react-icons/fa';
import { Jumbotron, Col, Row, Container } from 'react-bootstrap';
import { WEBSITES } from './PageList';
import './Portfolio.css';

function ListSites() {
  const mapSites = WEBSITES.map(sites => {
    return (
      <Col lg={3}md={6} sm={6}  className='p-5'>
        <section id="tiles" className='d-flex justify-content-center'>

          <article className={`${sites.backgroundColor}`}>
            <span className="image">
              <img src={sites.pic} alt={sites.title} />
            </span>
            <a href={sites.link} target="_blank" rel="noreferrer">
              <h2>{sites.title}</h2>
              
            </a>
          </article>
        </section>
        
      </Col>
    )
  })

  return (
    <Container fluid>
      <Row>
        {mapSites}
      </Row>
    </Container>
  )
}

function Portfolio() {
  return (
    <>
      <Jumbotron className='p-6 d-flex justify-content-center align-items-center flex-column portBackground text-light strokeBlack'>

        <FaSuitcase fontSize='3rem' className='mt-5'/>
        <h1>Portfolio</h1>
        
      </Jumbotron>

      <div className='d-flex flex-column text-center'>
        <h4>Did you know? We built this website.</h4>
        <p>Check out our portfolio of small projects and full fledged websites starting from earliest to most recent.</p>
      </div>
    
      <ListSites />
      
      <h3 className='d-flex justify-content-center p-5'>Many more websites to come!</h3>
    </>
  )
}

export default Portfolio;