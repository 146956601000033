import './App.css';
import Main from './Components/Main';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <Main/>
    </>
  );
}

export default App;
