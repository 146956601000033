export const WEBSITES = [
  {
    id: 0,
    pic: './img/paper-us.jpg',
    title: 'Paper-Us',
    link: 'https://paper-us.com/',
    backgroundColor: 'style1'
  },
  {
    id: 1,
    pic: './img/ocean-floor.png',
    title: 'Ocean Floor Services',
    link: 'https://oceanfloorservices.com/',
    backgroundColor: 'style2'
  },
  {
    id: 2,
    pic: './img/casa-del-taco.png',
    title: 'Casa Del Taco',
    link: 'https://casa-del-taco.netlify.app/',
    backgroundColor: 'style3'
  },
  {
    id: 3,
    pic: './img/idea-builder.png',
    title: 'Idea Builder',
    link: 'https://ideabprinting.com',
    backgroundColor: 'style4'
  },
  {
    id: 4,
    pic: './img/jo-landscape.png',
    title: 'Jo Landscape',
    link: 'https://reverent-feynman-39f058.netlify.app/',
    backgroundColor: 'style5'
  }
];